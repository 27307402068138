import { Link } from 'components/Share/Link'
import { Sort } from 'components/Sort/Sort'
import { IconSvg } from '@prism/icon'
import { AuctionHistoryContext } from 'contexts/BidHistoryContext'
import { Layout } from 'layouts/Layout'
import { useContext, useEffect } from 'react'
import { StyledHeaderActions, StyledStickyHeaderPage } from 'modules/VehicleList'
import { VehicleStickyBar } from 'modules/VehicleList/VehicleStickyBar'
import { VehicleStatuses, autionHistorySortOptions } from 'common/constants'
import { ISortBy } from 'types/baseTypes'
import { formatDateString } from 'utils/dateUtils'
import { SearchSection } from 'modules/BidHistory/SearchSection'
import { CurrencyCell, DataTable, DateWithoutTimeCell, DecimalCell } from 'components/DataTableHistory'
import { SelectColumnFilter, filterMultiSelect } from 'components/DataTableHistory/SelectColumnFilter'
import { OverlayLoader } from 'components/Loader'
import { Ribbon } from 'components/Ribbon/Ribbon'
import { PurchaseHistoryListSectionStyled } from 'modules/PurchaseHistory/PurchaseHistoryListSection.styled'
import { useGlobalStore } from 'store/useGlobalStore'
import { setListingBreadcrumb } from 'utils/breadcrumbUtils'
import { useLocation } from 'react-router-dom'
import NoActivity from 'modules/BuyerActivity/NoActivity'

export const BidHistory = () => {
  const { loading, result, searchFilter, updateSearchFilter } = useContext(AuctionHistoryContext)
  const getLocalText = useGlobalStore((state) => state.getLocalText)
  const location = useLocation()
  const handleSortChange = (selectedSortBy: ISortBy) => {
    updateSearchFilter({ ...searchFilter, SortBy: selectedSortBy })
  }

  useEffect(() => {
    const pageTitle = getLocalText('BID_HISTORY', 'Bid History')

    setListingBreadcrumb(pageTitle, location.pathname)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const getDownloadReportUrl = () => {
    var startDateValue = formatDateString(searchFilter.FromDate)
    var endDateValue = formatDateString(searchFilter.ToDate)
    var downloadReportUrl = `/resource/DownloadBidHistory?startDate=${startDateValue}&endDate=${endDateValue}&sortBy=${searchFilter.SortBy.Value}&sortOrder=${searchFilter.SortBy.SortOrder}`
    return downloadReportUrl
  }

  return (
    <Layout title={'Bid History'}>
      {loading && <OverlayLoader />}
      <StyledStickyHeaderPage>
        <VehicleStickyBar
          sectionTitle={'Bid History'}
          itemCount={result?.AllItems?.length}
          displayCollapse={false}
          rightActionSpan={9}
          rightActions={
            <StyledHeaderActions>
              <>
                <div className="export-view">{`Vehicles Won/Vehicles Bid On: ${
                  result?.AllItems?.filter((x) => x.BidHistoryStatus === 'Won').length
                }/${result?.AllItems?.length}`}</div>
                <Link to={getDownloadReportUrl()}>
                  <span className="right-action export-view ">
                    <IconSvg glyph="download" color="primary" />
                    &nbsp;Download Report&nbsp;
                  </span>
                </Link>

                <Sort
                  currentSort={searchFilter.SortBy}
                  sortOptions={autionHistorySortOptions}
                  onChange={handleSortChange}
                />
              </>
            </StyledHeaderActions>
          }
        />
        <SearchSection />
        <PurchaseHistoryListSectionStyled>
          {result?.AllItems?.length > 0 ? (
            <DataTable
              isFilterSearch
              filterRows="Title"
              columns={[
                {
                  Header: 'Description',
                  accessor: 'Title'
                },
                {
                  Header: 'VIN',
                  accessor: 'VIN'
                },
                {
                  Header: 'Odometer',
                  accessor: 'Odometer',
                  Cell: DecimalCell
                },
                {
                  Header: 'My Highest Bid',
                  accessor: 'UserHeighestBid',
                  Cell: CurrencyCell
                },
                {
                  Header: 'Price',
                  accessor: 'HeighestBid',
                  Cell: CurrencyCell
                },
                {
                  Header: 'Date',
                  accessor: 'AuctionEndDate',
                  Cell: DateWithoutTimeCell
                },
                {
                  Header: 'Vendor',
                  accessor: 'VendorTradingName',
                  Filter: SelectColumnFilter,
                  filter: filterMultiSelect
                },
                {
                  Header: 'Sales Channel',
                  accessor: 'SaleChannelType',
                  Filter: SelectColumnFilter,
                  filter: filterMultiSelect
                },
                {
                  Header: 'Status',
                  accessor: 'BidHistoryStatus',
                  Cell: ({ value }) =>
                    value.toLowerCase() === 'won' ? (
                      <Ribbon variant="success" label={value} key={value} />
                    ) : (
                      <Ribbon variant="danger" label={value} key={value} />
                    ),
                  Filter: SelectColumnFilter,
                  filter: filterMultiSelect
                }
              ]}
              data={result?.AllItems}
            />
          ) : (
            <NoActivity message={'No Bid Vehicle Found'} />
          )}
        </PurchaseHistoryListSectionStyled>
      </StyledStickyHeaderPage>
    </Layout>
  )
}
