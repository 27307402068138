import { PurchaseHistoryCard, PurchaseHistoryCardCol, PurchaseHistoryFormRow } from './PurchaseHistoryDetail.styled'
import VehicleDetailImage from 'modules/DealerVehicleManagement/VehicleDetailImage'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Col, Container, Form, InputGroup, OverlayTrigger, Popover, Row } from 'react-bootstrap'
import { StyledInfoLabel } from 'modules/DealerVehicleManagement/VehicleDetails.styled'
import { useFetch } from 'hooks/useFetch'
import { getOrderHubStatuses, getPurchaseHistory, saveDealerPurchaseRetailInfo } from 'apis/userApis'
import { Loader } from 'components/Loader'
import { useRef, useState } from 'react'
import readyauto from 'images/readyauto.png'
import { Link } from 'components/Share/Link'
import { PURCHASE_HISTORY_INIT_QUERY, READY_LOGISTIC_LINK } from 'common/constants'
import { faCheck } from '@fortawesome/free-solid-svg-icons'
import 'bs-stepper/dist/css/bs-stepper.min.css'
import DateTimePicker from 'components/DateTimePicker/DateTimePicker'
import { FormInput } from 'components/Forms'
import { FormattedMessage } from 'react-intl'
import { Formik, FormikHelpers } from 'formik'
import { useGlobalStore } from 'store/useGlobalStore'
import { shallow } from 'zustand/shallow'
import * as Yup from 'yup'
import SpinnerButton from 'components/Buttons/SpinnerButton'
import { DismissableAlert } from 'components/DismissableAlert/DismissableAlert'
import { ICustomerRetailInfo } from 'types/purchaseHistoryTypes'
import { useFormatNumber } from 'hooks/useIntl'
interface IProps {
  VIN: string
  isLoadApi: boolean
}
export const PurchaseHistoryInfoSection = ({ VIN, isLoadApi }: IProps) => {
  const { loading: vehicleLoading, data: vehicleData } = useFetch(
    () => getPurchaseHistory({ ...PURCHASE_HISTORY_INIT_QUERY, Vin: VIN, FromDate: null, ToDate: null }),
    [VIN]
  )
  const vehicle = vehicleData?.Items[0]
  const [userClaims, vendor] = useGlobalStore((state) => [state.userClaims, state.vendor], shallow)
  const [loading, setLoading] = useState<boolean>(false)
  const [isSaveSuccess, setIsSaveSuccess] = useState(false)
  const [responseMessage, setResponseMessage] = useState('')
  const { loading: orderHubLoading, data } = useFetch(
    () => (isLoadApi ? getOrderHubStatuses({ VehicleInstanceID: vehicle?.VehicleInstanceID, Vin: VIN }) : null),
    [vehicle, isLoadApi]
  )
  const handleSubmit = async (values: ICustomerRetailInfo, formikHelpers: FormikHelpers<ICustomerRetailInfo>) => {
    if (!!values) {
      setLoading(true)
      try {
        const response = await saveDealerPurchaseRetailInfo(values)
        if (response.Value > 0) {
          setIsSaveSuccess(true)
          setResponseMessage('Save Successfully')
        }
      } catch (error) {
        setIsSaveSuccess(false)
        setResponseMessage('Save Fail')
      }
      setLoading(false)
    }
  }

  const formatDecimal = useFormatNumber()

  const formSchema = Yup.object().shape({
    RetailCustomerName: Yup.string().trim().min(1, 'Please enter Customer Name').required('Please enter Customer Name'),
    RetailDate: Yup.date().required('Please Choose Retail Date')
  })

  const readyAutoElementRef = useRef(null)
  return (
    <>
      <PurchaseHistoryCard>
        {orderHubLoading || vehicleLoading ? (
          <Loader overlay />
        ) : (
          <Row className="detail-row-section">
            <Col lg={3} className="vehicle-image">
              <VehicleDetailImage
                vehicleInstance={vehicle?.VehicleInstanceID}
                mainImageUrl={vehicle?.ImageURL}
                images={null}
              ></VehicleDetailImage>
            </Col>
            <Col className="detail-col-section">
              <Container fluid>
                <Row className="px-5">
                  <PurchaseHistoryCardCol>
                    <Row>
                      <StyledInfoLabel>Odometer:</StyledInfoLabel> {formatDecimal(+vehicle?.Mileage)}
                    </Row>
                    <Row>
                      <StyledInfoLabel>Condition:</StyledInfoLabel> {vehicle?.VehicleCondition}
                    </Row>
                  </PurchaseHistoryCardCol>
                  <PurchaseHistoryCardCol>
                    <Row>
                      <StyledInfoLabel>Sales Channel:</StyledInfoLabel> {vehicle?.SaleChannel}
                    </Row>
                    <Row>
                      <StyledInfoLabel>Vehicle Location:</StyledInfoLabel> {vehicle?.Location}
                    </Row>
                  </PurchaseHistoryCardCol>
                  <PurchaseHistoryCardCol>
                    <Row>
                      <StyledInfoLabel>Order Number:</StyledInfoLabel> {vehicle?.InvoiceNumber}
                    </Row>
                    {vehicle?.IsReadyLogistics && (
                      <OverlayTrigger
                        rootClose={true}
                        container={readyAutoElementRef}
                        trigger="click"
                        placement="left"
                        overlay={
                          <Popover id="popover-basic">
                            <Popover.Title className="ready-auto-title">
                              <img src={readyauto} alt="ready auto" />
                            </Popover.Title>
                            <Popover.Content className="ready-auto-content">
                              <div className="vehicle-info-wrapper">
                                <div className="ready-auto-img">
                                  <img src={vehicle?.ImageURL} alt="ready auto" />
                                </div>
                                <div className="vehicle-info">
                                  <div>{vehicle?.Title}</div>
                                  <div>{vehicle?.Vin}</div>
                                </div>
                              </div>
                              {data?.TotalRecords >= 0 && data?.Items[0]?.OrderHubStatuses?.length >= 0 && (
                                <div className="ready-auto-info">
                                  <div className="bs-stepper vertical">
                                    <div className="bs-stepper-header" role="tablist">
                                      {data.Items[0].OrderHubStatuses.map((element, index) => (
                                        <>
                                          <div className="step">
                                            {element.OrderHubStatusID !== 11 && (
                                              <div className="bs-stepper-circle">
                                                <div className="fas fa-align-center" aria-hidden="true">
                                                  <FontAwesomeIcon icon={faCheck} color="white" />
                                                </div>
                                              </div>
                                            )}
                                            <div className="info">
                                              <div className="bs-stepper-label">{element.MappedStatus}</div>
                                              {element.OrderHubStatusID < 7 &&
                                                element.OrderHubStatusID < 6 &&
                                                element.EstimatedPickupDateString !== '' && (
                                                  <div className="bs-stepper-label">
                                                    {element.EstimatedPickupDateString}
                                                  </div>
                                                )}
                                              {element.OrderHubStatusID < 7 &&
                                                element.OrderHubStatusID < 6 &&
                                                element.EstimatedPickupDateString === '' &&
                                                element.SourceEventDateString !== '' && (
                                                  <div className="bs-stepper-label">
                                                    {element.SourceEventDateString}
                                                  </div>
                                                )}
                                              {element.OrderHubStatusID === 6 &&
                                                element.ActualPickupDateString !== '' && (
                                                  <div className="bs-stepper-label">
                                                    {element.ActualPickupDateString}
                                                  </div>
                                                )}
                                              {element.OrderHubStatusID === 6 &&
                                                element.ActualPickupDateString === '' && (
                                                  <div className="bs-stepper-label">
                                                    {element.SourceEventDateString}
                                                  </div>
                                                )}
                                              {element.OrderHubStatusID >= 7 &&
                                                element.ActualPickupDateString !== '' && (
                                                  <div className="bs-stepper-label">
                                                    {element.ActualDeliveryDateString}
                                                  </div>
                                                )}
                                              {element.OrderHubStatusID >= 7 &&
                                                element.ActualPickupDateString === '' &&
                                                element.SourceEventDateString !== '' && (
                                                  <div className="bs-stepper-label">
                                                    {element.SourceEventDateString}
                                                  </div>
                                                )}
                                            </div>
                                          </div>
                                          {index < data?.Items[0].OrderHubStatuses.length - 1 && (
                                            <div className="bs-stepper-line"></div>
                                          )}
                                        </>
                                      ))}
                                    </div>
                                  </div>
                                </div>
                              )}

                              <div className="ready-auto-footer">
                                <Link className="link-history" to={READY_LOGISTIC_LINK} target="_blank">
                                  For more information, please click here to go to Ready Logistics
                                  <br />
                                </Link>
                                For immediate assistance please contact: <br />
                                480-558-3200 <br />
                                BGDReadySupport@coxautoinc.com
                              </div>
                            </Popover.Content>
                          </Popover>
                        }
                      >
                        <Row>
                          <StyledInfoLabel className="ready-auto-link" ref={readyAutoElementRef}>
                            Transportation Status:
                          </StyledInfoLabel>{' '}
                          {vehicle?.OrderHubLatestStatus}
                        </Row>
                      </OverlayTrigger>
                    )}
                  </PurchaseHistoryCardCol>
                </Row>
              </Container>
              <PurchaseHistoryFormRow>
                <Container>
                  <Formik
                    initialValues={
                      {
                        RetailCustomerName: vehicle?.RetailCustomerName ? vehicle?.RetailCustomerName : '',
                        RetailDate: vehicle?.RetailDate ? new Date(vehicle?.RetailDate) : new Date(),
                        CreatedUserName: userClaims.Username,
                        VendorId: vendor.ID,
                        VehicleInstanceId: vehicle?.VehicleInstanceID,
                        BuyerVehiclePurchaseId: vehicle?.BuyerVehiclePurchaseID
                      } as ICustomerRetailInfo
                    }
                    validationSchema={formSchema}
                    onSubmit={handleSubmit}
                  >
                    {(props) => (
                      <Form onSubmit={props.handleSubmit}>
                        <Row>
                          <Col>
                            <Row className="mx-3">
                              <Col lg={6}>
                                <FormInput
                                  placeholder="Enter Customer Name"
                                  name="RetailCustomerName"
                                  label="Customer Name:"
                                  type="text"
                                  error={props.errors.RetailCustomerName}
                                  onChange={props.handleChange}
                                  value={props.values.RetailCustomerName}
                                  isInvalid={!!props.errors.RetailCustomerName}
                                />
                              </Col>
                              <Col className="retail-date" lg={6}>
                                <Form.Group id="RetailDate" className="mb-3">
                                  <Form.Label>
                                    <FormattedMessage id="Retail Date:" />
                                  </Form.Label>
                                  <InputGroup>
                                    <DateTimePicker
                                      className="date-picker-input form-control"
                                      name="RetailDate"
                                      dateFormat="MM/dd/yyyy"
                                      placeholderText="MM/DD/YYYY"
                                      value={props.values.RetailDate}
                                      selected={props.values.RetailDate}
                                      onTouch={props.setFieldTouched}
                                      onChange={(e) => {
                                        props.setFieldValue('RetailDate', new Date(e))
                                      }}
                                      maxDate={new Date()}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                      {props.errors.RetailDate}
                                    </Form.Control.Feedback>
                                  </InputGroup>
                                </Form.Group>
                              </Col>
                            </Row>
                            <Row xs={12} className="mx-4 mb-3">
                              <SpinnerButton
                                style={{ width: '100%' }}
                                isLoading={loading}
                                type="submit"
                                className="form-btn"
                                variant="primary"
                              >
                                Submit
                              </SpinnerButton>

                              <Container fluid className="mx-0 px-0 mt-3">
                                <DismissableAlert
                                  variant={isSaveSuccess ? 'success' : 'danger'}
                                  message={responseMessage}
                                  autoDismissable
                                  onClose={() => setResponseMessage('')}
                                ></DismissableAlert>
                              </Container>
                            </Row>
                          </Col>
                        </Row>
                      </Form>
                    )}
                  </Formik>
                </Container>
              </PurchaseHistoryFormRow>
            </Col>
          </Row>
        )}
      </PurchaseHistoryCard>
    </>
  )
}

export default PurchaseHistoryInfoSection
