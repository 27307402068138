import { FormatDateOptions, FormatNumberOptions, useIntl } from 'react-intl'
import { findIana } from 'windows-iana'
import { DateTime } from 'luxon'
import { useGlobalStore } from 'store/useGlobalStore'
import { shallow } from 'zustand/shallow'

export const useFormatDateTime = (dateTimeOptions?: FormatDateOptions) => {
  const { formatDate, formatTime } = useIntl()
  const vendor = useGlobalStore((state) => state.vendor, shallow)
  const timeZone = dateTimeOptions?.timeZone ?? findIana(vendor.TimeZoneRegistryID)?.[0]
  return (value: string | number | Date) => {
    if (!value || value === '0001-01-01T00:00:00Z') return ''

    const defaultDateOptions: FormatDateOptions = {
      timeZone: timeZone,
      year: 'numeric',
      month: '2-digit',
      day: '2-digit'
    }

    const defaultTimeOptions: FormatDateOptions = {
      timeZone: timeZone,
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      hourCycle: 'h23'
    }

    let result = `${formatDate(value, { ...defaultDateOptions, ...dateTimeOptions })}`

    result += ` ${formatTime(value, { ...defaultTimeOptions, ...dateTimeOptions })}`
    return result
  }
}

export const useFormatDate = (dateTimeOptions?: FormatDateOptions) => {
  const vendor = useGlobalStore((state) => state.vendor, shallow)
  const timeZone = dateTimeOptions?.timeZone ?? findIana(vendor.TimeZoneRegistryID)?.[0]
  return (value: string | number | Date) => {
    if (!value || value === '0001-01-01T00:00:00Z') return ''

    let result = `${DateTime.fromISO(new Date(value).toISOString(), { zone: timeZone }).toFormat('MM/dd/yyyy')}`

    return result
  }
}

export const useFormatNumber = (formatNumberOption?: FormatNumberOptions) => {
  const { formatNumber } = useIntl()

  return (value: number | bigint) => {
    if (value == null) {
      return ''
    }

    const defaultNumberOptions: FormatNumberOptions = {
      style: 'decimal'
    }
    return formatNumber(value, { ...defaultNumberOptions, ...formatNumberOption })
  }
}

export const useNewFormatDate = (dateTimeOptions?: FormatDateOptions) => {
  const vendor = useGlobalStore((state) => state.vendor, shallow)
  const timeZone = dateTimeOptions?.timeZone ?? findIana(vendor.TimeZoneRegistryID)?.[0]
  return (value: string | number | Date) => {
    if (!value || value === '0001-01-01T00:00:00Z') return ''

    let result = `${DateTime.fromISO(new Date(value).toISOString(), { zone: timeZone }).toFormat('MM/dd/yyyy h:mm a')}`

    return result
  }
}
